import React, { useEffect } from "react";
import { CircularProgress, Container } from "@mui/material";

export const Landing = ({ target }: { target: string | undefined }) => {
  useEffect(() => {
    window.location.replace(target !== undefined ? target : "/index.php");
  }, []);

  return (
    <Container style={{ textAlign: "center", padding: "2dvw" }}>
      <CircularProgress variant="indeterminate" />
    </Container>
  );
};
